import React from "react"
import { useMatch } from "@reach/router"
import SEO from "../components/seo"
import Loadable from "@loadable/component"
import Header from "../components/Header/Header"
import Banner from "../components/Home/Banner/HomeBanner"
import { VACANCIES_PAGE_URL } from "../components/common/site/constants";

const Footer = Loadable(() => import("../components/Footer/Footer"))
const About = Loadable(() => import("../components/Home/About/About"))
const StaticContent = Loadable(() => import("../components/StaticContent/StaticContent"))
const StaticContentSidebar = Loadable(() => import("../components/StaticContent/StaticContentSidebar"))
const BookValuation = Loadable(() => import("../components/Home/BookValuation/BookValuation"))
const FormBlock = Loadable(() => import("../components/Home/FormBlock/FormBlock"))
const ServiceContentOne = Loadable(() => import("../components/ServiceContentBlock/ServiceContentOne"))
const ServiceContentTwo = Loadable(() => import("../components/ServiceContentBlock/ServiceContentTwo"))
const CountBlock = Loadable(() => import("../components/Home/CountBlock/CountBlock"))
const GlobalCount = Loadable(() => import('../components/Home/CountBlock/GlobalCount'))
const Accreditations = Loadable(() => import("../components/Accreditations/Accreditations"))
const StaffStory = Loadable(() => import("../components/StaffStory"))
const FAQSection = Loadable(() => import("../components/FAQ/FAQSection"))
const Benefits = Loadable(() => import("../components/Benefits/Benefits"))
const FAQComponent = Loadable(() => import("../components/FAQ/FAQComponent"))
const GuidesSection = Loadable(() => import("../components/Guides/HandyGuides"))
const NewsInsights = Loadable(() => import('../components/Home/NewsInsights/NewsInsights'))



const Modules = props => {

  const vacanciesList = useMatch(VACANCIES_PAGE_URL.alias + "/:slug")
  return (
    <React.Fragment>
      <SEO
        title={props.page.Meta_Title}
        description={props.page.Meta_Description}  image={props.page.Banner_Image ? props.page.Banner_Image.url : ''}
      />
      <div
        className={
          props.page.Layout === "Homepage"
            ? "home-header"
            : props.page.Layout === "Default"
              ? "wrapper area-guide-wrapper"
              : props.page.Layout === "Staticpage" || props.page.Layout === "Staticpage_Sidebar" || props.page.Layout === "Calculator_or_Form" ||
                props.page.Layout === "Background_Image_Layout"
                ? "wrapper static-page"
                : ""
        }
      >
        <Header />

        {props.page.Layout === "Homepage" &&
          props.page.Banner_Image &&
          props.page.Choose_Menu[0].URL === "home" && (
            <Banner {...props.page} />
          )}

        {props.page.Layout === "Homepage" &&
          props.page.Banner_Image &&
          props.page.Choose_Menu[0].URL !== "home" && (
            <Banner {...props.page} />
          )}
      </div>
      {props.modules &&
        props.modules.map((module, i) => {
          return (
            <div key={i}>
              {module.Intro_Content && <About {...module} />}
              {module.Add_Static_Content && (
                <>
                  {module.Style == "With_Sidebar" ? (
                    <StaticContentSidebar
                      {...module}
                      {...props.page}
                      count={i}
                    />
                  ) : (
                    <StaticContent {...module} style={module.Style} count={i} vacanciesList={vacanciesList} />
                  )}
                </>
              )}
              {module.Select_Module &&
                module.Select_Module.CTA_1_Link == null && (
                  <FormBlock {...module.Select_Module} />
                )}

              {module.Select_Module && module.Select_Module.CTA_1_Link && (
                <BookValuation {...module.Select_Module} />
              )}
              {/**Service content block*/}
              {module.Add_New_Block && (
                <>
                  {module.Add_New_Block && module.Add_New_Block.length !== 2 &&
                    module.Add_New_Block.slice(0, 1).map((service, i) => {
                      return (
                        <ServiceContentOne
                          {...service}
                          length={module.Add_New_Block.length}
                          key={i} />
                      )
                    })}
                  {module.Add_New_Block.length > 1 && (
                    <ServiceContentTwo {...module} />
                  )}
                </>
              )}

              {/* Benefits block */}
              {module.Add_Benefit && <Benefits {...module} />}
              {/**Statistics block*/}
              {module.Add_Stats && <CountBlock {...module} />}
              {module.Stats_Title && <GlobalCount {...module} />}
              {/**Staff story block*/}
              {module.Add_Staff_Stories && <StaffStory {...module} />}
              {/**FAQ block*/}

              {/* Collection Block */}
              {module.Select_Collection === "FAQs" && (
                <FAQComponent {...module} />
              )}
              {/* End of Collections Block */}

              {module.Select_FAQs && <FAQSection {...module} />}

              {/* Guides */}
              {module.Select_Guides_here && <GuidesSection {...module} />}
              {/* News & Latest_News  */}
              {module.Latest_News_Heading && module.Latest_News_Intro_Content &&
                <NewsInsights {...module} />
              }
              {module.Add_Accreditation &&
                  <Accreditations {...module} />
              }
            </div>
          )
        })}
      <Footer />
    </React.Fragment>
  )
}
export default Modules
